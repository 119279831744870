<script>
import Multiselect from '@vueform/multiselect';
import flatPickr from "vue-flatpickr-component";
import 'flatpickr/dist/flatpickr.min.css';
import { Portuguese } from 'flatpickr/dist/l10n/pt.js';
import { vMaska } from "maska";
import { DateTime } from "luxon"
import FilterBuilder from './filter-builder.vue'
import { useRoute } from 'vue-router';
import { sendFunctionRequest } from '../../helpers/dynamicTable/sendFunctionRequest';
import Swal from 'sweetalert2';
import { getValueListById } from '../../helpers/forms/getListaValorById';
import { debounce } from 'vue-debounce';
import { v4 as uuidv4 } from 'uuid';
import htmlHelp from './htmlHelp';

export default {
    props: {
        sis_campos_tabela: {
            type: Array,
            required: true,
        },
        sis_filtros: {
            type: Array,
            required: true,
        },
        programSchema: {
            type: Object,
            required: true,
        },
        updateFiltros: {
            type: Function,
            required: true,
        },
        search: {
            type: Function,
            required: true,
        },
        loadingSearch: {
            type: Boolean,
            required: false,
        }
    },
    components: {
        Multiselect,
        flatPickr,
        FilterBuilder
    },
    directives: {
        maska: vMaska
    },
    watch: {
        sis_filtros: {
            handler: function (val) {
                this.filtros = val
                if(this.$store.state.programFilter.loadedFilter){
                    const filter = this.filtros.find(f => f.id_filtro_programa === this.$store.state.programFilter.loadedFilter.id_filtro_programa)
                    if(filter){
                        this.$store.commit('programFilter/setLoadedFilter', {id_filtro_programa: filter.id_filtro_programa, id_programa: filter.id_programa, titulo: filter.titulo})
                    }
                    if(!filter){
                        this.setInitialLoadedFilter(this.programSchema)
                    }
                }
            },
            deep: true
        }
    },
    data() {
        return {
            clearBuilderFields: false,
            showHelpModal: false,
            uuidv4: () => uuidv4(),
            loadingFilter: false,
            loadingEditionFilterTarget: false,
            lastEditedFilterId: null,
            filtros: [],
            browser_route: useRoute(),
            DateTime,
            backdrop: "static",
            loadingModal: 0,
            value: null,
            date: null,
            newCondition: [],
            // filterSave: [],
            loadingSave: false,
            tags: [],
            showFiltersModal: false,
            loadedFilter: null,
            showFilterBuilder: false,
            currentEditionFiltroPrograma: null,
            operators: [
                { "order": 1, "id": 1, "label": 'Igual a', "simbol": '=', "suportedTypes": [1,2,4,5,6,7,8,11,12,13] },
                { "order": 3, "id": 2, "label": 'Maior que', "simbol": '>', "suportedTypes": [4,5,6,7,12] },
                { "order": 5, "id": 3, "label": 'Menor que', "simbol": '<', "suportedTypes": [4,5,6,7,12] },
                { "order": 2, "id": 4, "label": 'Diferente de', "simbol": '<>', "suportedTypes": [1,4,5,6,7,8,11,12,13] },
                { "order": 7, "id": 5, "label": 'Entre', "simbol": 'BETWEEN', "suportedTypes": [4,5,6,7,12] },
                { "order": 8, "id": 6, "label": 'Contem', "simbol": '~*', "suportedTypes": [1] },
                { "order": 4, "id": 7, "label": 'Maior ou Igual a', "simbol": '>=', "suportedTypes": [4,5,6,7,12] },
                { "order": 6, "id": 8, "label": 'Menor ou Igual a', "simbol": '<=', "suportedTypes": [4,5,6,7,12] },
                { "order": 8, "id": 9, "label": 'Contido Em', "simbol": 'IN', "suportedTypes": [7,12,13] },
                { "order": 9, "id": 10, "label": 'Não Contido Em', "simbol": 'NOT IN', "suportedTypes": [7,12,13] },
                { "order": 10, "id": 11, "label": 'Não Contem', "simbol": '!~*', "suportedTypes": [1] },
            ],
            options: this.$props.sis_campos_tabela.filter(sct => sct.tipo_campo != 15).map((sct, i) => {
                return {
                    ...sct,
                    index: i + 1
                }
            })
        }
    },
    methods: {
        hideModal(){
            this.showFiltersModal = false;
            this.newCondition = []
        },
        async showEditionFilterModal(item) {
            this.loadingEditionFilterTarget = true
            this.showFilterBuilder = true

            // make request to get the filter
            const filter = await this.fetchFilterById(item.id_filtro_programa)     
            if(!filter) {
                this.loadingEditionFilterTarget = false
                this.showFilterBuilder = false  
                Swal.fire({
                    icon: "warning",
                    text: "Erro ao carregar o filtro",
                    confirmButtonColor: "#34c38f",
                    confirmButtonText: "OK",
                })
                return
            }
            const filterWithParams = this.showFilterParams(filter)
            this.currentEditionFiltroPrograma = filterWithParams
            this.loadingEditionFilterTarget = false
            this.showFilterBuilder = true
        },
        getDefaultDateConfig(id_simbol, tipo_campo, valor_padrao) {
            const fieldType = tipo_campo;
            const defaultValue = valor_padrao
            const rangeDateconfig = {
                mode: "single", 
                dateFormat: "Y-m-d",
                altInput: true,
                altFormat: "d/m/Y",
                enableTime: true,
                allowInput: true,
                locale: Portuguese,
                static: true,
            }

            if([4, 5, 6].includes(fieldType)){
                if (this.operators.find(op => op.id === id_simbol).simbol === "BETWEEN") {
                    rangeDateconfig.mode = "range";
                    rangeDateconfig.defaultDate = defaultValue && (typeof defaultValue === 'string' ||defaultValue instanceof String) ? 
                            defaultValue === 'now()'  ? [new Date(this.DateTime.now()), new Date(this.DateTime.now().plus({days: 1}))] 
                            : [new Date(defaultValue.split(' até ')[0]), new Date(defaultValue.split(' até ')[1])] 
                        : null 
                } else{
                    rangeDateconfig.defaultDate = defaultValue ? defaultValue === 'now()'  ? new Date(this.DateTime.now()) : null : null
                    rangeDateconfig.mode = "single";
                }
            }
            switch (fieldType) {
                case 4:
                    rangeDateconfig.enableTime = false;
                    rangeDateconfig.altFormat = 'd/m/Y ';
                    rangeDateconfig.dateFormat = 'Y-m-d';
                    break;
                case 5:
                    rangeDateconfig.altFormat = 'd/m/Y H:i:S';
                    rangeDateconfig.dateFormat = 'Y-m-d\\TH:i:S';
                    rangeDateconfig.noCalendar = false;
                    rangeDateconfig.enableTime = true;
                    rangeDateconfig.enableSeconds = true;
                    break;
                case 6:
                    rangeDateconfig.dateFormat = "H:i:S";
                    rangeDateconfig.noCalendar = true;
                    rangeDateconfig.enableTime = true;
                    rangeDateconfig.enableSeconds = true;
                    break;
            }

            return rangeDateconfig
        },
        handleChangeTag(tags, index, tagIndex = null) {
            const typeField = this.options[this.newCondition[index].selectedField - 1].tipo_campo
            let value = tags
            if(typeField === 12){
                value = tags.replace(/[^\d.,]/g, '')
                const dots = value.match(/\./g)
                if(dots && dots.length > 1){
                    value = value.replace(/\./g, '')
                    value = value.slice(0, value.length - 1) + '.' + value.slice(value.length - 1)
                }
            }

            if(tagIndex !== null){
                this.newCondition[index].tags[tagIndex] = value
            }else{
                this.newCondition[index].tags = value;
            }
        },
        handleSwitchChange(bool, index){
            this.newCondition[index].tags = bool
        },
        async saveAndSearch() {
            if(this.newCondition.length === 0 || !this.todosInputsPreenchidos){
                return
            }
            await this.save()
        },
        async desselectProgramFilter() {
            this.loadingFilter = true
            const body = {
                id_filtro_programa: null,
                id_programa: this.browser_route.params.targetId,
                condicao_adicional: "",
                lista_id_empresa: JSON.parse(localStorage.getItem('companiesGlobalFilter')) ?? [],
                data_trabalho: JSON.parse(localStorage.getItem('dataTrabalho')),
                navegador: false,
                ordenacao: null,
                quantidade_registros: 1,
                pagina: 0,
                rota: 'salva_dados_filtro_programa_usuario',
            }
            const res = await sendFunctionRequest(body, null, null, false)
            if(res){
                const data = res.data
                if (!data.status) {
                    Swal.fire({
                        icon: "warning",
                        text: data.mensagem ? data.mensagem : data.erro,
                        confirmButtonColor: "#34c38f",
                        confirmButtonText: "OK",
                    })
                } else {
                    this.setInitialLoadedFilter({id_filtro_programa: null, id_programa: this.browser_route.params.targetId})
                    this.search()
                }
            }else{
                console.log('error', res)
            }
            this.loadingFilter = false
        },
        async save() {
            this.$store.commit('programFilter/setSearchRoute', 'salva_dados_filtro_programa_usuario')
            this.$store.commit('programFilter/setData',  [])
            if(this.loadedFilter.hasParams){
                this.$store.commit('programFilter/appendData', 
                    {
                        key: 'sis_parametro_filtro_programa_usuario',
                        data: this.newCondition.map(condition => {
                            let value = ''

                            if(this.options[condition.selectedField -1].tipo_campo === 2 && (condition.tags !== true && condition.tags !== false)){
                                value = false
                            }else if(Array.isArray(condition.tags)){
                                if(condition.selectedCondition === 9 || condition.selectedCondition === 10){
                                    value = condition.tags.join(',').replace(/,+/g, ',').replace(/^,|,$/g, '')
                                }else if(condition.selectedCondition === 5){
                                    value = condition.tags.join(' até ')
                                }
                            }else if(condition.selectedCondition === 5 && condition.tags.split(' até ').length === 1){
                                value = condition.tags + ' até ' + condition.tags
                            }else if(condition.tags){
                                value = condition.tags.toString()
                            }else{
                                value = ''
                            }
                            
                            return {
                                id_parametro_filtro_programa: condition.id_parametro_filtro_programa,
                                valor:  value
                            }
                        })
                    }
                )
            }
            this.$store.commit('programFilter/setLoadedFilter',  {id_filtro_programa: this.loadedFilter.id_filtro_programa, id_programa: this.loadedFilter.id_programa, titulo: this.loadedFilter.titulo})
            this.search()
            this.showFiltersModal = false
        },
        async loadFilter(filterId, show = true) {
            this.loadingFilter = true
            const filter = await this.fetchFilterById(filterId)
            this.loadingFilter = false            
            if(!filter) {
                Swal.fire({
                    icon: "warning",
                    text: "Erro ao carregar o filtro",
                    confirmButtonColor: "#34c38f",
                    confirmButtonText: "OK",
                })
                return
            }

            const filterWithParams = this.showFilterParams(filter)
            this.loadedFilter = filterWithParams
            if(!this.loadedFilter.hasParams){
                this.save()
                return
            }
            this.newCondition = this.loadedFilter.condicoes.map(cond => {
                return { ...cond, selectedField: this.options.findIndex(sct => sct.id_campo_tabela === cond.selectedFieldId) + 1 }
            })
            for(let i = 0; i < this.newCondition.length; i++){
                if(this.newCondition[i].id_lista_valor){
                    this.updateOptionsList(this.newCondition[i].id_lista_valor, i)
                }
            }
            this.showFiltersModal = show
        },
        async updateOptionsList(id_lista_valor, index, searchString = ''){
            if(id_lista_valor === null && this.newCondition[index]){
                this.newCondition[index].sis_opcao_lista_valor = null;
            }else if(id_lista_valor && this.newCondition[index]){
                this.newCondition[index].loadingOptions = true;
                this.newCondition[index].sis_opcao_lista_valor = null;
                await getValueListById(id_lista_valor, searchString)
                .then(list => {
                    if(list.sis_lista_valor.sis_opcao_lista_valor){
                        this.newCondition[index].sis_opcao_lista_valor = list.sis_lista_valor.sis_opcao_lista_valor
                    }
                    if(list.sis_lista_valor.exige_filtro){
                        this.newCondition[index].lista_exige_filtro = true
                    }
                    this.newCondition[index].loadingOptions = false;
                }).catch(err => {
                    console.error(err)
                    this.newCondition[index].loadingOptions = false;
                })
            }
        },
        updateOptionsListFiltro: debounce(function(searchString, id_lista_valor, index){
                if(searchString.length < 3){
                    return
                }
                this.updateOptionsList(id_lista_valor, index, searchString)
            }, 500),
        async fetchFilterById(id){
            const body = {
                id_programa: this.browser_route.params.targetId,
                id_filtro_programa: id,
                condicao_adicional: "",
                lista_id_empresa: JSON.parse(localStorage.getItem('companiesGlobalFilter')) ?? [],
                data_trabalho: JSON.parse(localStorage.getItem('dataTrabalho')),
                navegador: false,
                ordenacao: null,
                quantidade_registros: 1,
                pagina: 0,
                rota: 'obtem_dados_filtro_programa_usuario',
            }
            const res = await sendFunctionRequest(body, null, null, false)
            if(res){
                const data = res.data
                if (!data.status) {
                    Swal.fire({
                        icon: "warning",
                        text: data.mensagem ? data.mensagem : data.erro,
                        confirmButtonColor: "#34c38f",
                        confirmButtonText: "OK",
                    })
                    return null
                }
                return data.retorno
            }else{
                console.log('error', res)
            }
        },
        showFilterParams(filter) {
            const params = filter.sis_parametro_filtro_programa?.sort((a, b) => a.ordem_exibicao - b.ordem_exibicao)
            const dynamicCondition = filter.sis_condicao_filtro_programa[0].condicao.split(' $$ ')[0]
            if(!params){
                return {
                    id_filtro_programa: filter.id_filtro_programa,
                    id_programa: filter.id_programa,
                    titulo: filter.titulo,
                    descricao: filter.descricao,
                    publico: filter.publico,
                    customizado: filter.customizado,
                    padrao: filter.tipo_filtro === 1,
                    hasParams: false
                }
            }
            const conditions = dynamicCondition.split(' :AND: ')
            const fixedCondition = filter.sis_condicao_filtro_programa[0].condicao.split(' $$ ')[1] ?? null
            const filterWithParams = {
                condicoes: params.map(p => {
                    const cond = conditions.find(c => c.includes(':' + p.id_parametro_filtro_programa))
                    const id_simbol = cond.includes('!~*') ? 11 : cond.includes('NOT IN') ? 10 : cond.includes('IN') ? 9 : cond.includes('>=') ? 7 : cond.includes('<=') ? 8 : cond.includes('<>') ? 4 : cond.includes('>') ? 2 : cond.includes('<') ? 3 : cond.includes('~*') ? 6 : cond.includes('BETWEEN') ? 5 : cond.includes('=') ? 1 : null
                    const ids = cond.match(/:\S+/g)
                    const column = this.sis_campos_tabela.filter(sct => ids.map(id => id.replace(':', '')).includes(sct.id_campo_tabela))[0]
                    const v = p.valor_usuario !== null ? p.valor_usuario : p.valor
                    const value = v ? v.includes(' até ') && ![4,5,6].includes(column.tipo_campo) ? v.split(' até ') : [9, 10].includes(id_simbol) ? v.split(',') : v : []
                    const originalValue = p.valor ? [9, 10].includes(id_simbol) ? v.split(',') : p.valor.includes(' até ') && ![4,5,6].includes(column.tipo_campo) ? p.valor.split(' até ') : p.valor.replace('now()', this.DateTime.now().toISODate()) : []
                    const userValue = p.valor_usuario ? [9, 10].includes(id_simbol) ? v.split(',') : p.valor_usuario.includes(' até ') && ![4,5,6].includes(column.tipo_campo) ? p.valor_usuario.split(' até ') : p.valor_usuario.replace('now()', this.DateTime.now().toISODate()) : []
                    const newFilter = {
                        id_filtro_programa: filter.id_filtro_programa,
                        id_parametro_filtro_programa: p.id_parametro_filtro_programa,
                        titulo: p.titulo,
                        ordem_exibicao: p.ordem_exibicao,
                        required: p.obrigatorio,
                        id_lista_valor: p.id_lista_valor,
                        lista_exige_filtro: p.sis_lista_valor?.exige_filtro,
                        searchString: '',
                        sis_opcao_lista_valor: p.sis_lista_valor?.sis_opcao_lista_valor,
                        selectedFieldId: column.id_campo_tabela,
                        selectedCondition: id_simbol,
                        tags: value,
                        originalValue: originalValue,
                        userValue: userValue,
                        rangeDateconfig: this.getDefaultDateConfig(id_simbol, column.tipo_campo, value),
                        dynamicDate: v && v.includes('now()'),
                        originalDynamicDate: p.valor && p.valor.includes('now()'),
                        loadingSaveValue: false,
                        loadingRemoveValue: false,
                        loadingOptions: false,
                    }
                    return newFilter
                }),
                id_filtro_programa: filter.id_filtro_programa,
                id_programa: filter.id_programa,
                titulo: filter.titulo,
                descricao: filter.descricao,
                publico: filter.publico,
                customizado: filter.customizado,
                padrao: filter.tipo_filtro === 1,
                fixedCondition: fixedCondition,
                hasParams: true
            }
            return filterWithParams
        },
        setInitialLoadedFilter(programSchema = {id_filtro_programa: null, id_programa: null}){
            const { id_filtro_programa, id_programa } = programSchema
            if(id_filtro_programa){
                const filterToBeLoaded = this.filtros.find(f => f.id_filtro_programa === id_filtro_programa)
                if(filterToBeLoaded){
                    this.$store.commit('programFilter/setLoadedFilter', {id_filtro_programa: filterToBeLoaded.id_filtro_programa, id_programa: id_programa, titulo: filterToBeLoaded.titulo})
                }
            }else{
                const filterToBeLoaded = this.filtros.find(f => f.tipo_filtro === 1)
                if(filterToBeLoaded){
                    this.$store.commit('programFilter/setLoadedFilter', {id_filtro_programa: filterToBeLoaded.id_filtro_programa, id_programa: id_programa, titulo: filterToBeLoaded.titulo})
                }
                if(!filterToBeLoaded){
                    this.$store.commit('programFilter/setLoadedFilter', null)
                }
            }
        },
        setDynamicDate(bool, index){
            if(bool){
                if(this.newCondition[index].selectedCondition === 5){
                    const previousValue = this.newCondition[index].tags
                    const newValue = this.newCondition[index].tags?.replace(this.DateTime.now().toISODate(), 'now()')
                    if(!previousValue || previousValue === newValue){
                        Swal.fire({
                            icon: "warning",
                            text: `A data inicial ou final deve ser igual a data de hoje (${this.DateTime.now().toLocaleString()})!`,
                            confirmButtonColor: "#34c38f",
                            confirmButtonText: "OK",
                        });
                    }else{
                        this.newCondition[index].dynamicDate = true
                        this.newCondition[index].tags = newValue
                    }
                }else{
                    this.newCondition[index].dynamicDate = true
                    this.newCondition[index].tags = 'now()'
                }
            }else{
                this.newCondition[index].dynamicDate = false
                if(this.newCondition[index].selectedCondition === 5){
                    this.newCondition[index].tags = this.newCondition[index].tags.replace('now()', this.DateTime.now().toISODate())
                }else{
                    this.newCondition[index].tags = this.DateTime.now().toISODate()
                }
            }
        },
    },
    mounted() {
        this.filtros = this.sis_filtros
        this.setInitialLoadedFilter(this.programSchema)
    },
    computed: {
        todosInputsPreenchidos() {
            for (const condition of this.newCondition) {
                if(this.options[condition.selectedField - 1].tipo_campo === 2){
                    return true    
                }
                if (!condition.selectedField || !condition.selectedCondition || (condition.required && (condition.tags === null || typeof condition.tags === 'undefined' || condition.tags.length == 0))) {
                    return false;
                }
            }
            return true;
        },
        getDynamicDateText(){
            return (tags) => {
                if(tags === 'now()'){
                    return 'Data Atual'
                }

                const [date1, date2] = tags.split(' até ')
                if(date1 === 'now()'){
                    return `Data Atual até ${this.DateTime.fromISO(date2).toLocaleString()}`
                }
                if(date2 === 'now()'){
                    return `${this.DateTime.fromISO(date1).toLocaleString()} até Data Atual`
                }
            }
        },
        getFilterHelpHtml(){
            if(!this.showHelpModal){
                return ''
            }
            return htmlHelp
        }
    }
}
</script>

<template>
    <div class="d-flex ms-auto gap-0">
        <button type="button" v-if="!loadingSearch"
            class="btn btn-primary rounded-0" @click="() => search()"  v-b-tooltip.hover.top="'Atualizar Pesquisa'">
            <i class="ri-search-line align-bottom me-1"></i>
        </button>
        <button type="button" v-else
            class="btn btn-primary rounded-0" disabled>
            <span class="spinner-border spinner-border-sm align-bottom" role="status" aria-hidden="true"></span>
        </button>
        <button :disabled="loadingSearch" type="button" class="btn btn-primary p-2 btn-load d-flex gap-1 rounded-0 rounded-end border-white border-top-0 border-bottom-0 dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <span v-if="loadingFilter" class="d-flex align-items-center justify-content-center gap-2">
                <span class="spinner-border flex-shrink-0" role="status"></span>
            </span>
            <i v-else class="ri-filter-line"></i> 
            <span>{{ $store.state.programFilter.loadedFilter ? $store.state.programFilter.loadedFilter.titulo : 'Filtros' }}</span>
        </button>
        <div class="dropdown-menu p-0 m-0 border-0 bg-white" style="">
            <div class="list-group" style="width:100%;min-width:320px;">
                <div v-if="this.filtros.length > 0" class="justify-content-between align-items-center">
                    <div class="d-flex" v-for="(filtro, index) in this.filtros" :key="index">
                        <button @click="desselectProgramFilter($store.state.programFilter.loadedFilter?.id_filtro_programa)" v-if="$store.state.programFilter.loadedFilter?.id_filtro_programa === filtro.id_filtro_programa" type="button" v-b-tooltip.hover.top="'Desselecionar'"
                            class="btn btn-danger border rounded-0">
                            <i class="mdi mdi-close align-bottom me-1"></i>
                        </button>
                        <button @click="loadFilter(filtro.id_filtro_programa, true, true)"
                            :class="['list-group-item list-group-item-action border-none', $store.state.programFilter.loadedFilter?.id_filtro_programa === filtro.id_filtro_programa ? 'bg-primary text-white' : '']" type="button">
                            <span><i class="align-middle me-2"></i>
                                {{ filtro.titulo }}
                            </span>
                        </button>
                        <button v-if="filtro.customizado" @click="showEditionFilterModal(filtro)" type="button" v-b-tooltip.hover.right="'Editar'" class="btn btn-light border text-warning rounded-0"><i class="mdi mdi-pencil"></i></button>
                    </div>
                </div>
                <div v-else class="list-group-item list-group-item-action alert-light" role="alert">
                    <span href="#" class="alert-link fw-normal">Nenhum <b>filtro</b> disponível</span>
                </div>
                <div class="d-flex">
                    <button
                        @click="() => {currentEditionFiltroPrograma = null;clearBuilderFields = !clearBuilderFields; showFilterBuilder = true;}"
                        class="btn btn-light flex-1 rounded-0 border d-flex align-items-center justify-content-center text-primary" type="button">
                        <i class="mdi mdi-plus-circle fs-18"></i>
                        <span class="fw-semibold"><i class="align-middle me-2"></i>
                            Novo Filtro
                        </span>
                    </button>
                    <button @click="showHelpModal = true" type="button" class="btn btn-light border rounded-0 d-flex align-items-center justify-content-center text-primary" v-b-tooltip.hover.top="'Ajuda'">
                        <i class="mdi mdi-help-circle fs-18"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="text-muted d-flex align-items-center gap-2 flex-wrap mb-0 mt-3">
        <b-modal size="lg" hide-footer fade centered hide-header body-class="p-0 border-0" footer-class="border-0" v-model="showFiltersModal"
            @hidden="hideModal">
            <div
                class="modal-content border-0"
            >
                <div class="modal-body p-0">
                    <div class="card-header mb-5 pb-3 pt-4">
                        <h4 class="text-center">{{loadedFilter?.titulo}}</h4>
                    </div>
                    <form @submit.prevent="saveAndSearch">
                        <div v-for="(filter) in newCondition" :key="filter.id_parametro_filtro_programa">
                            <div class="px-4 mx-auto d-flex justify-content-center flex-wrap w-100 border-none mb-1 filters">
                                <!-- TITULO DO PARÂMETRO -->
                                <div class="d-flex flex-1 form-control bg-primary border-0 border rounded-0 rounded-start h-100 p-0" style="min-height: 40px;">
                                    <div class="flex-1 d-flex align-items-center">
                                        <div type="text" class="ms-2 text-white">{{ filter.titulo }}</div>
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <div class="symbol-label border border-end-0 rounded bg-light px-1 text-muted me-2">{{this.operators.find(op => op.id === filter.selectedCondition).label}}</div>
                                    </div>
                                </div>
                                <!-- SELECIONAR OS PARAMETROS -->
                                <div class="position-relative flex-1">
                                    <div v-if="filter.loadingOptions && !filter.lista_exige_filtro" class="loading">
                                        <div class="lds-facebook">
                                        </div> Carregando Opções
                                    </div>
                                    <div v-else-if="['IN', 'NOT IN'].includes(this.operators.find(op => op.id === filter.selectedCondition).simbol)" class="d-flex flex-wrap border rounded-end">
                                        <div v-for="(value, tagIndex) in filter.tags" :key="tagIndex" class="d-flex mb-2 mx-1 border-bottom flex-1">
                                            <div v-if="filter.lista_exige_filtro" class="text-dark w-100">
                                                <Multiselect  autocomplete="one-time-code"  
                                                    placeholder="Selecione o valor padrão"
                                                    @search-change="updateOptionsListFiltro($event, filter.id_lista_valor, filter.ordem_exibicao-1, tagIndex)"
                                                    v-model="filter.tags[tagIndex]"
                                                    style="border: none;padding:0;min-width: 200px;"
                                                    :searchable="true" 
                                                    :options="filter.sis_opcao_lista_valor" 
                                                    :no-options-text="filter.loadingOptions ? 'Carregando...' : 'Digite para pesquisar'"
                                                    :value-prop="'id'" label='titulo'
                                                />
                                            </div>
                                            <div v-else-if="filter.sis_opcao_lista_valor" class="text-dark w-100">
                                                <Multiselect  autocomplete="one-time-code"  
                                                    placeholder="Selecione o valor padrão"
                                                    v-model="filter.tags[tagIndex]"
                                                    style="border: none;padding:0;min-width: 200px;"
                                                    :multiple="false" 
                                                    :searchable="true" 
                                                    :options="filter.sis_opcao_lista_valor" 
                                                    :clearOnSearch="true"
                                                    :value-prop="'id'" label='titulo'
                                                />
                                            </div>
                                            <input 
                                                v-else
                                                type="text"
                                                placeholder="Informe um valor padrão"
                                                class="form-control border-0 rounded-0 rounded-end flex-1"
                                                style="min-height: 40px;"
                                                v-model="filter.tags[tagIndex]"
                                                @input="handleChangeTag($event.target.value, filter.ordem_exibicao-1, tagIndex)"
                                            />
                                            <button 
                                                type="button"
                                                class="mt-2 btn text-danger btn-light btn-icon btn-sm rounded-circle mx-1"
                                                v-b-tooltip.hover.top="'Remover Valor'"
                                                @click="filter.tags.splice(tagIndex, 1)"
                                            >
                                                <i class="mdi mdi-close-thick fs-15" />
                                            </button>
                                        </div>
                                        <button
                                            @click="filter.tags.push('')"
                                            type="button"
                                            class="my-auto btn text-success btn-light btn-icon btn-sm rounded-circle mx-1 mt-2"
                                            v-b-tooltip.hover.top="'Adicionar Valor'"
                                        >
                                            <i class="ri-add-line fs-22" />
                                        </button>
                                    </div>
                                    <div v-else-if="filter.lista_exige_filtro" class="text-dark">
                                        <Multiselect  autocomplete="one-time-code" 
                                            placeholder="Selecione um valor"
                                            @search-change="updateOptionsListFiltro($event, filter.id_lista_valor, filter.ordem_exibicao-1)"
                                            v-model="filter.tags"
                                            :classes="{
                                                wrapper: 'multiselect-wrapper border h-100'
                                            }"
                                            style="min-height: 38px; padding: 0"
                                            class="border-0 rounded-0"
                                            :searchable="true"
                                            :options="filter.sis_opcao_lista_valor"
                                            :no-options-text="filter.loadingOptions ? 'Carregando...' : 'Digite para pesquisar'"
                                            :value-prop="'id'" label='titulo'
                                        />
                                    </div>
                                    <div v-else-if="filter.sis_opcao_lista_valor" class="h-100">
                                        <Multiselect  autocomplete="one-time-code"  v-model="filter.tags"
                                            :classes="{
                                                wrapper: 'multiselect-wrapper border h-100'
                                            }"
                                            style="height:100%;min-height: 38px; padding: 0"
                                            class="border-0 rounded-0" :multiple="false" :searchable="true"
                                            :options="filter.sis_opcao_lista_valor" placeholder="Selecione o valor"
                                            :clearOnSearch="true" :value-prop="'id'" label="titulo" :disabled="loadingSave"/>
                                    </div>
                                    <template v-else-if="[4, 5, 6].includes(options[filter.selectedField - 1].tipo_campo)">
                                        <div class="input-group h-100">
                                            <input v-if="filter.dynamicDate" 
                                                type="text" 
                                                class="form-control border-0 border-top border-bottom rounded-0 text-muted"
                                                style="min-height: 40px;" :value="getDynamicDateText(filter.tags)" readonly
                                            >
                                            <div class="flex-1 index" v-if="!filter.dynamicDate">
                                                <flat-pickr
                                                    :id="uuidv4()"
                                                    v-model="filter.tags" :class="['form-control border-0 h-100 rounded-0 flatpickr-custom-style', options[filter.selectedField - 1].tipo_campo === 4 ? 'flatpickr-input' : '']"
                                                    @keyup.enter="saveAndSearch"
                                                    :placeholder="filter.rangeDateconfig.mode !== 'single' ? 'Selecione o período' : 'Selecione a data'"
                                                    :config="filter.rangeDateconfig" :simbol="filter.selectedCondition">
                                                </flat-pickr>
                                            </div>
                                            <div class="input-group-prepend" v-if="options[filter.selectedField - 1].tipo_campo === 4">
                                                <button 
                                                    @click="setDynamicDate(!filter.dynamicDate, filter.ordem_exibicao - 1)"
                                                    type="button"
                                                    style="height:40px;border-color:#ced4da !important;" 
                                                    :class="['btn btn-icon rounded-0 rounded-end', filter.dynamicDate ? 'btn-primary text-light' : 'text-primary']" 
                                                    v-b-tooltip.hover.top="'Data Atual'">
                                                    <i class="mdi mdi-timeline-clock fs-18"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </template>
                                    <div class="d-flex h-100  align-items-center  form-check form-switch form-switch-lg ms-3"
                                        dir="ltr" v-else-if="options[filter.selectedField - 1].tipo_campo == 2">
                                        <input 
                                            type="checkbox" 
                                            class="mr-auto form-check-input"
                                            role="switch"
                                            @click="handleSwitchChange($event.target.checked, filter.ordem_exibicao-1)"
                                            :disabled="loadingSave"
                                            :checked="filter.tags === true || filter.tags === 'true'"
                                        >
                                    </div>
                                    <div v-else-if="['BETWEEN'].includes(this.operators.find(op => op.id === filter.selectedCondition).simbol)"
                                        class="d-flex flex-wrap flex-md-nowrap align-items-center border rounded-0"
                                    >
                                        <input 
                                            v-if="options[filter.selectedField - 1].tipo_campo == 7"
                                            type="number"
                                            placeholder="Primeiro valor"
                                            v-model="filter.tags[0]"
                                            @keyup.enter="saveAndSearch"
                                            class="form-control border-0 rounded-0 rounded-end"
                                            step="1"
                                            style="min-height: 40px;"
                                            :disabled="loadingSave"
                                        />
                                        <input
                                            v-else
                                            type="text"
                                            placeholder="Primeiro valor"
                                            class="form-control border-0 rounded-0 rounded-end"
                                            style="min-height: 40px;"
                                            v-model="filter.tags[0]"
                                            @input="handleChangeTag($event.target.value, filter.ordem_exibicao-1, 0)"
                                            @keyup.enter="saveAndSearch"
                                            :disabled="loadingSave"
                                        />
                                        <div class="text-muted px-2">Até</div>
                                        <input 
                                            v-if="options[filter.selectedField - 1].tipo_campo == 7"
                                            type="number"
                                            placeholder="Primeiro valor"
                                            v-model="filter.tags[1]"
                                            @keyup.enter="saveAndSearch"
                                            class="form-control border-0 rounded-0 rounded-end"
                                            step="1"
                                            style="min-height: 40px;"
                                            :disabled="loadingSave"
                                        />
                                        <input
                                            v-else
                                            type="text"
                                            placeholder="Primeiro valor"
                                            class="form-control border-0 rounded-0 rounded-end"
                                            style="min-height: 40px;"
                                            v-model="filter.tags[1]"
                                            @input="handleChangeTag($event.target.value, filter.ordem_exibicao-1, 1)"
                                            @keyup.enter="saveAndSearch"
                                            :disabled="loadingSave"
                                        />
                                    </div>
                                    <input v-else
                                        type="text"
                                        placeholder="Informe um valor"
                                        class="form-control border-0 border-top rounded-0 shadow"
                                        style="min-height: 40px;"
                                        v-model="filter.tags"
                                        @input="handleChangeTag($event.target.value, filter.ordem_exibicao-1)"
                                        @keyup.enter="saveAndSearch"
                                        :disabled="loadingSave"
                                    />
                                    <div v-if="filter.required" :class="['position-absolute top-0 text-danger fs-5 required-mark', options[filter.selectedField - 1].tipo_campo === 4 ? 'space-right' : '']">*</div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <p class="m-0 mt-5 text-muted mx-4 mb-3">
                        {{ loadedFilter?.descricao }}
                    </p>
                </div>
                <div class="modal-footer border-top pt-2 d-flex justify-content-end" style="max-height: 75px;">
                    <div class="d-flex gap-1">
                        <button class="btn btn-light border" style="width: 135px;" data-bs-dismiss="modal">Fechar</button>
                        <button v-if="!loadingSave" :disabled="newCondition.length === 0 || !todosInputsPreenchidos" 
                            type="button" class="btn btn-primary" style="width: 135px;"
                            @click="saveAndSearch"
                        >Pesquisar</button>
                        <button v-else class="btn btn-primary btn-load" style="width: 135px;">
                            <span class="d-flex align-items-center justify-content-center gap-2">
                                <span class="spinner-border flex-shrink-0" role="status"></span> Pesquisar
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </b-modal>

        <b-modal hide-footer fade centered hide-header v-model="showFilterBuilder"
            @hidden="() => showFilterBuilder = false">
            <div
                class="modal-content" style="
                    overflow-y:auto;
                    width: 80vw;
                    height: 90vh;
                    position: fixed;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);"
            >
                <FilterBuilder
                    :sis_campos_tabela="sis_campos_tabela" 
                    :sis_filtros="this.filtros.filter(f => f.customizado)"
                    :programSchema="programSchema"
                    :currentEditionFiltroPrograma="currentEditionFiltroPrograma"
                    :loadingEditionFilterTarget="loadingEditionFilterTarget"
                    :updateCurrentEditionFiltroPrograma="(v) => currentEditionFiltroPrograma = v"
                    :updateFiltros="updateFiltros"
                    :updateShowFilterBuilder="(v) => showFilterBuilder = v"
                    :operators="operators"
                    :onDeleteFilter="() => this.loadedFilter = null"
                    :setLastEditedFilterId="(id) => this.lastEditedFilterId = id"
                    :clearBuilderFields="clearBuilderFields"
                />
            </div>
        </b-modal>
    </div>

    <b-modal id="helpModal" fade scrollable centered size="xl" hide-header hide-footer v-model="showHelpModal">
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <div class="mt-4 markdown">
                <template v-if="showHelpModal">
                    <div v-html="getFilterHelpHtml"></div>
                </template>
    
                <div class="hstack gap-2 justify-content-center mt-5">
                  <button @click="() => showHelpModal = false" type="button" class="btn btn-light"
                    data-bs-dismiss="modal">Fechar</button>
                </div>
              </div>
            </div>
          </div>
        </div>
    </b-modal>
</template>

<style src="@vueform/multiselect/themes/default.css"></style>
<style @scoped>
.markdown h1,
.markdown h2,
.markdown h3,
.markdown h4,
.markdown h5,
.markdown h6 {
  padding-bottom: 0.3em;
  border-bottom: 1px solid #ccc;
}
.markdown a {
  color: #08a88a;
  text-decoration: underline !important;
}
.index > .flatpickr-wrapper{
    width: 100%;
}
.flatpickr-custom-style{
    height: 100%;
    min-height: 40px !important;
    border: 1px solid #ced4da !important;
    border-radius: 0 3px 3px 0 !important;
}
.flatpickr-custom-style.flatpickr-input{
    border-right: none !important;
    border-top-right-radius: 0!important;
    border-bottom-right-radius: 0!important;
}
.required-mark{
    right:15px;
}
.required-mark.space-right{
    right:40px;
}
.multiselect{
    max-height: 100%;
}
.symbol-label{
    border-color: #ced4da !important;
}
</style>